$(() => {
  // 求人一覧のその他の特徴のJS
  const $toggle = $('#js-search-option-toggle');
  const $collapse = $('#js-search-option-collapse');
  $('#js-search-option-toggle').on('click', () => {
    if ($collapse.is(':visible')) {
      $collapse.stop().slideUp();
      $toggle.find('a').text('その他の特徴をみる');
    } else {
      $collapse.stop().slideDown();
      $toggle.find('a').text('戻す');
    }
  });

  // 求人検索のフォームデータを整形し、リダイレクト
  const form = document.querySelector('#js-search-form');
  const formTopSp = document.querySelector('#js-search-form_top_sp');
  const spSearchMenu = document.querySelector('#search_menu');
  if (spSearchMenu || formTopSp) {
    // SP時
    if (spSearchMenu) searchHandle(spSearchMenu);
    if (formTopSp) searchHandle(formTopSp);
  } else if (form) {
    // PC時
    searchHandle(form);
  }
});

// 検索ボタンを押された際に実行される関数
function searchHandle(form) {
  const action = form.getAttribute('action');
  form.addEventListener('submit', (event) => {
    event.preventDefault();
    const fd = new FormData(form);

    // 入力がないデータを取り除いたものを newFormData に入れる
    const newFormData = createFD(fd);

    // 検索結果のURLを整形
    let searchURL = action;
    let firstFlag = true;
    for (const [key, value] of newFormData.entries()) {
      searchURL = `${searchURL + (firstFlag ? '?' : '&')}${key}=${value}`;
      firstFlag = false;
    }

    // 整形したURLでリダイレクト
    window.location.href = encodeURI(searchURL).replace(/,/g, '%2C');
  });
}

// 入力がないデータを取り除いたものを newFormData に入れる
function createFD(fd) {
  const newFormData = new FormData();
  // keyのソートのため、ループ文で代入しない。
  if (fd.get('jobs_search[free_word]')) newFormData.append('jobs_search[free_word]', fd.get('jobs_search[free_word]'));
  if (fd.get('jobs_search[category_names]')) newFormData.append('jobs_search[category_names]', fd.get('jobs_search[category_names]'));
  if (fd.getAll('jobs_search[category_names][]').length !== 0) {
    let categoryNames = '';
    let firstFlag = true;
    for (const name of fd.getAll('jobs_search[category_names][]')) {
      if (categoryNames.includes(name) === false) {
        categoryNames = categoryNames + (firstFlag ? '' : ',') + name;
        firstFlag = false;
      }
    }
    newFormData.append('jobs_search[category_names]', categoryNames);
  }
  if (fd.get('jobs_search[type_names]')) newFormData.append('jobs_search[type_names]', fd.get('jobs_search[type_names]'));
  if (fd.getAll('jobs_search[type_names][]').length !== 0) {
    let typeNames = '';
    let firstFlag = true;
    for (const name of fd.getAll('jobs_search[type_names][]')) {
      if (typeNames.includes(name) === false) {
        typeNames = typeNames + (firstFlag ? '' : ',') + name;
        firstFlag = false;
      }
    }
    newFormData.append('jobs_search[type_names]', typeNames);
  }
  if (fd.get('jobs_search[license_names]')) newFormData.append('jobs_search[license_names]', fd.get('jobs_search[license_names]'));
  if (fd.getAll('jobs_search[license_names][]').length !== 0) {
    let licenseNames = '';
    let firstFlag = true;
    for (const name of fd.getAll('jobs_search[license_names][]')) {
      if (licenseNames.includes(name) === false) {
        licenseNames = licenseNames + (firstFlag ? '' : ',') + name;
        firstFlag = false;
      }
    }
    newFormData.append('jobs_search[license_names]', licenseNames);
  }
  if (fd.get('jobs_search[prefecture_names]')) newFormData.append('jobs_search[prefecture_names]', fd.get('jobs_search[prefecture_names]'));
  if (fd.getAll('jobs_search[prefecture_names][]').length !== 0) {
    let prefectureNames = '';
    let firstFlag = true;
    for (const name of fd.getAll('jobs_search[prefecture_names][]')) {
      if (prefectureNames.includes(name) === false) {
        prefectureNames = prefectureNames + (firstFlag ? '' : ',') + name;
        firstFlag = false;
      }
    }
    newFormData.append('jobs_search[prefecture_names]', prefectureNames);
  }
  if (fd.get('jobs_search[annual_salary_min]') && fd.get('jobs_search[annual_salary_min]') !== 'null') newFormData.append('jobs_search[annual_salary_min]', fd.get('jobs_search[annual_salary_min]'));
  if (fd.get('jobs_search[annual_salary_max]') && fd.get('jobs_search[annual_salary_max]') !== 'null') newFormData.append('jobs_search[annual_salary_max]', fd.get('jobs_search[annual_salary_max]'));
  if (fd.get('jobs_search[contract_names]')) newFormData.append('jobs_search[contract_names]', fd.get('jobs_search[contract_names]'));
  if (fd.getAll('jobs_search[contract_names][]').length !== 0) {
    let contactNames = '';
    let firstFlag = true;
    for (const name of fd.getAll('jobs_search[contract_names][]')) {
      if (contactNames.includes(name) === false) {
        contactNames = contactNames + (firstFlag ? '' : ',') + name;
        firstFlag = false;
      }
    }
    newFormData.append('jobs_search[contract_names]', contactNames);
  }
  if (fd.get('jobs_search[feature_names]')) newFormData.append('jobs_search[feature_names]', fd.get('jobs_search[feature_names]'));
  if (fd.getAll('jobs_search[feature_names][]').length !== 0) {
    let featureNames = '';
    let firstFlag = true;
    for (const name of fd.getAll('jobs_search[feature_names][]')) {
      if (featureNames.includes(name) === false) {
        featureNames = featureNames + (firstFlag ? '' : ',') + name;
        firstFlag = false;
      }
    }
    newFormData.append('jobs_search[feature_names]', featureNames);
  }
  if (fd.get('jobs_search[service_type]')) newFormData.append('jobs_search[service_type]', fd.get('jobs_search[service_type]'));
  if (fd.get('jobs_search[created_by_client]')) newFormData.append('jobs_search[created_by_client]', fd.get('jobs_search[created_by_client]'));
  if (fd.get('jobs_search[is_incentive_ad]')) newFormData.append('jobs_search[is_incentive_ad]', fd.get('jobs_search[is_incentive_ad]'));
  return newFormData;
}
